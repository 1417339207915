<template>
<div class="home--iot">
  <div class="home--header">
      <els-text-card v-bind="cardData"></els-text-card>
    </div>
  <div class="iot-home">
    <div class="title">{{lang.funcIntro}}</div>
    <div class="list-content" v-for="item of functionData" :key="item.icon">
      <div class="header">
        <div class="icon">
          <svg aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
        </div>
        <div class="text">
          <div>{{item.title1}}</div>
          <div>{{item.title2}}</div>
        </div>
      </div>
      <div class="content">
        <div class="spot-list" v-for="item1 of item.content" :key="item1">
          <div class="spot"></div>
          <div class="text">{{item1}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
  // import {data as list} from "@/assets/json/IndustrialMechanism.js"
  // import {arrayToTree} from "@/views/components/rulesDesign/utils.js"
  import mixinsI18n from "@/mixins/i18n.js"
  export default {
    name: "index",
    mixins: [mixinsI18n],
    data () {
      return {
        PAGE_NAME: "homePage",
      }
    },
    computed:{
      cardData() {
        const data = {
          title: this.lang.platformName,
          desc: this.lang.desc,
          // image:'https://www.hanyuniip.cn/img/image-iot.bbe023ec.png',
          image:'/static/image/image-iot.png',
          imageWidth:'300px',
          layout:'right-left',
          titleColor:'#fff',
          descColor:'#ddd',
        }
        return data
      },
      functionData() {
        const data = [this.lang.funData1,this.lang.funData2,this.lang.funData3,this.lang.funData4];
          return data
      }
    },
    methods: {
      bannerShowControl(v) {
        // parent为basicContainer
        // const parent = this.$parent;
        // parent.bannerShowControl(v);
      },
    },
    mounted () {
      // this.bannerShowControl(true);
      // const tree = arrayToTree(list,['level1','level2','level3','name'])
      // console.log(JSON.stringify(tree))
    },
    beforeDestroy () {
      // this.bannerShowControl(false);
    }
  }
</script>

<style scoped lang='scss'>
.home--iot{
  box-sizing: border-box;
  padding:2px;
}
.home--header{
  height:260px;
  background:#081b33;
}
  .iot-home {
    padding: 56px 10.41%;
    .title {
      font-size:32px;
      font-weight:500;
      text-align: center;
    }
    .list-content {
      background: #FFF;
      margin-top: 24px;
      .header {
        display: flex;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid #DDD;
        .icon svg{
          width: 40px;
          height: 40px;
        }
        .text {
          padding-left: 16px;
          font-size: 16px;
          color: #666;
          div:first-child {
            padding-bottom: 6px;
          }
          div:last-child {
            font-size: 14px;
            font-weight:400;
            line-height: 26px;
            color: rgba(51, 51, 51, 0.65);
          }
        }
      }
      .content {
        padding: 24px;
        .spot-list {
          display: flex;
          align-items: center;
          line-height:28px;
          .spot {
            width: 4px;
            height: 4px;
            border-radius: 2px;
            margin-right: 4px;
            background: #0091FF;
          }
          .text {
            color:rgba(51,51,51,0.65);
            font-size:14px;
          }
        }
      }
    }
  }
</style>
